$(document).ready(function () {
    jQuery('.multiple_barcode .custom_status_parent').on('click', function () {
        window.location.href = "T&T_Inner.html";
    });
    $('.dropdown-menu a').click(function () {
        $('#selected').text($(this).text());
    });

    $('.show_more_desktop').click(function () {
        $('.show_desktop_barcode').toggleClass('active');
        if ($('.show_desktop_barcode').hasClass("active")) {
            $(this).html("Show less results");
        } else {
            $(this).html("Show more results");
        }
    });
    $('.show_more_mobile').click(function () {
        $('.show_mobile_barcode').toggleClass('active');
        if ($('.show_mobile_barcode').hasClass("active")) {
            $(this).html("Show less results");
        } else {
            $(this).html("Show more results");
        }
    });

    $('.wrapper').on('click', '.panel-title', function () {
        if ($(this).children('a').hasClass('collapsed')) {
            $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-chevron-down');
        } else {
            $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-chevron-right');
        }
    });

    $('.wrapper.faq-listing').on('click', '.panel-title', function () {
        if ($(this).children('a').hasClass('collapsed')) {
            $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-minus');
        } else {
            $(this).find('i.more-less').removeClass().addClass('more-less glyphicon glyphicon-plus');
        }
    });

    $('.opening-hours').on('click', 'p', function () {
        $(this).find('i').toggleClass("glyphicon-chevron-down").toggleClass("glyphicon-chevron-right");
        $('.opening-hours-body').toggleClass("show-div");
    });

    $('#pagination-demo').twbsPagination({
        totalPages: 2,
        // the current page that show on start
        startPage: 1,

        // maximum visible pages
        visiblePages: 2,

        initiateStartPageClick: true,

        // template for pagination links
        href: false,

        // variable name in href template for page number
        hrefVariable: '{{number}}',

        // carousel-style pagination
        loop: false,

        // callback function
        onPageClick: function (event, page) {
            $('.page-active-desktop').removeClass('page-active-desktop');
            $('#page' + page).addClass('page-active-desktop');
        },

        // pagination Classes
        paginationClass: 'pagination',
        nextClass: 'next',
        prevClass: 'prev',
        lastClass: 'last',
        firstClass: 'first',
        pageClass: 'page',
        activeClass: 'active',
        disabledClass: 'disabled'

    });

    $('#pagination-demo-1').twbsPagination({
        totalPages: 2,
        // the current page that show on start
        startPage: 1,

        // maximum visible pages
        visiblePages: 2,

        initiateStartPageClick: true,

        // template for pagination links
        href: false,

        // variable name in href template for page number
        hrefVariable: '{{number}}',

        // carousel-style pagination
        loop: false,

        // callback function
        onPageClick: function (event, page) {
            $('.page-active').removeClass('page-active');
            $('#page-mobile-' + page).addClass('page-active');
        },

        // pagination Classes
        paginationClass: 'pagination',
        nextClass: 'next',
        prevClass: 'prev',
        lastClass: 'last',
        firstClass: 'first',
        pageClass: 'page',
        activeClass: 'active',
        disabledClass: 'disabled'

    });
    
});

function openForm() {
    document.getElementById("myForm").style.display = "block";
}

function closeForm() {
    document.getElementById("myForm").style.display = "none";
}


/*function allowNumbersOnly(e) {
    var code = (e.which) ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
        e.preventDefault();
    }
}*/
